import gql from "graphql-tag";

export default gql`
  query OrganizationWithBillingInfo($id: ID!) {
    organizationUsage: organization(id: $id) {
      id
      name
      billingCycle
      UsageCycle(orderBy: cycle_DESC) {
        inboxCount
        autoReplyCount
        outboundCount
        cycle
      }
      plan {
        id
        name
        price
        rate
        amountMessages
      }
      campaigns {
        id
        createdAt
        sentAt
        schedule {
          id
          when
          sent
        }
        message {
          id
          success
          failed
          messageCount
        }
      }
    }
  }
`;
