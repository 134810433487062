import gql from 'graphql-tag';

export default gql`
# Write your query or mutation here
  mutation updateUser(
    $id: ID!,
    $organizationId: ID,
    $subAccountId: ID,
    $changeOrganization: Boolean
    ) {
      updateUser(
        id: $id,
        organizationId: $organizationId,
        subAccountId: $subAccountId,
        changeOrganization: $changeOrganization
      ) {
        id
      }
  }`
