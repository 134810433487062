import gql from "graphql-tag";

export default gql`
  query SubOrgs($id: ID!) {
    organizationToOrganization(where: { parentOrganization: { id: $id } }) {
      id
      parentOrganization {
        id
        name
        from
        customDomain
        site
        tollFrees {
          id
          phone
        }
        industry {
          id
          name
        }
        plan {
          id
          name
        }
      }
      subOrganization {
        id
        name
        from
        customDomain
        site
        tollFrees {
          id
          phone
        }
        industry {
          id
          name
        }
        plan {
          id
          name
        }
      }
    }
  }
`;
