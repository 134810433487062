import gql from "graphql-tag";

export default gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      webhookURL
      APIKey {
        value
      }
      name
      from
      site
      inboundPhone
      autoReplyMessage
      industry {
        id
        name
      }
      billingCycle
      customDomain
      plan {
        id
        name
        amountMessages
      }
      tollFrees {
        id
        phone
        organizations {
          id
          name
        }
      }
      users {
        id
        fname
        lname
        email
        phone
        role
        lastLogin
        subAccount {
          id
          name
        }
        isMain
      }
      subAccounts {
        id
        name
        users {
          id
          fname
          lname
          email
          role
        }
      }
      parentOrg {
        id
        name
      }
      subOrgs {
        id
        name
      }
      UsageCycle(orderBy: cycle_DESC) {
        inboxCount
        autoReplyCount
        outboundCount
        cycle
      }
    }
  }
`;
