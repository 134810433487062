import gql from "graphql-tag";

export default gql`
  query me {
    me {
      id
      isMain
      organization {
        id
        tollFrees {
          phone
        }
      }
    }
  }
`;
